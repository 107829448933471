export const KEYBOARD_KEYS = {
  ENTER: 13,
  NUM_1: 49,
  NUM_2: 50,
  NUM_3: 51,
  NUM_4: 52,
  NUM_5: 53,
  NUM_6: 54,
  KEY_Q: 81,
  KEY_X: 88,
  KEY_A: 65,
  KEY_T: 84,
  KEY_V: 86,
  KEY_E: 69,
  KEY_G: 71,
  KEY_M: 77,
  KEY_P: 80,
  KEY_N: 78,
  KEY_S: 83,
  KEY_C: 67,
  KEY_R: 82,
  KEY_I: 73,
  KEY_B: 66,
  KEY_D: 68,
  KEY_F: 70,
  KEY_O: 79,
};
